<template>
  <div>
    <ul>
      <li>
        <p>一、版权</p>（1）兰州市残疾人就业创业网络服务平台内的所有内容和图表受《中华人民共和国著作权法》及相关法律法规和中国加入的所有知识产权方面的国际条约的保护。兰州市残疾人就业创业网络服务平台对平台内容享有完整的著作权，未经其允许，不得以任何形式复制和采用。在内容和图表不作任何修改、保留性内容未做修改。
        （2）申请使用兰州市残疾人就业创业网络服务平台的许可按特别批准原则进行。
      </li>
      <li>
        <p>二、商标</p>（1）兰州市残疾人就业创业网络服务平台内的所有内容和图表受《中华人民共和国著作权法》及相关法律法规和中国加入的所有知识产权方面的国际条约的保护。兰州市残疾人就业创业网络服务平台对平台内容享有完整的著作权，未经其允许，不得以任何形式复制和采用。在内容和图表不作任何修改、保留性内容未做修改。
        （2）申请使用兰州市残疾人就业创业网络服务平台的许可按特别批准原则进行。
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import "~assets/css/total.less";
</style>
